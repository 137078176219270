<template>
  <v-dialog v-model="state" persistent width="400px">
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
        ref="form"
        @submit.prevent="handleSubmit(assignAttendantToPumps)"
        class="px-3"
      >
        <v-card flat>
          <v-card-title>
            <span class="font-weight-bold blue-grey--text"
              >Assign Attendants To Pumps</span
            >
            <v-spacer></v-spacer>
            <v-progress-linear
              v-show="progress"
              class="mx-6"
              indeterminate
              color="cyan"
            ></v-progress-linear>
          </v-card-title>
          <v-card-text>
            <v-col>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    :nudge-left="10"
                    :nudge-top="30"
                    offset-y
                    min-width="auto"
                    class="ft font-weight-medium  "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        v-model="attendantToPump.date"
                        outlined
                        readonly
                        label="Choose a Date"
                        :error="validateDate(attendantToPump.date)"
                        :error-messages="
                          validateDate(attendantToPump.date)
                            ? 'Date cannot be in the future'
                            : errors[0]
                        "
                      />
                    </template>
                    <v-date-picker
                      class="font font-weight-medium  "
                      v-model="attendantToPump.date"
                      :max="maxDate | timestamp('YYYY-MM-DD')"
                      :min="minDate"
                      @input="searchRecords"
                    ></v-date-picker>
                  </v-menu>
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :items="pumps"
                    :error-messages="errors[0]"
                    item-value="id"
                    item-text="name"
                    v-model="attendantToPump.pumpId"
                    outlined
                    label="Choose a pump"
                  />
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :items="pumpAttendants"
                    item-value="id"
                    :error-messages="errors[0]"
                    v-model="attendantToPump.attendantId"
                    item-text="name"
                    class="font font-weight-medium"
                    label="Select one or more pump attendants"
                    small-chips
                    color="blue"
                    outlined
                  />
                </ValidationProvider>
              </v-row>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="close" class="mr-2 text-capitalize">
              Cancel
            </v-btn>
            <v-btn
              type="submit"
              color="primary"
              :loading="loading"
              :disabled="validateDate(attendantToPump.date) || loading"
              class="text-capitalize"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
import moment from "moment";
import { timestamp } from "../../../filters/timestamp";
import { mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "Field cannot be empty"
});
export default {
  name: "AssignAttendantToPumpDialog",
  props: {
    state: {
      type: Boolean,
      default: false
    },
    minDate: String,
    progress: Boolean,
    loading: {
      type: Boolean,
      default: false
    },
    selectedDate: String
  },

  data: () => ({
    date: "",
    menu: false,
    maxDate: new Date(),
    attendantToPump: {
      pumpId: "",
      date: "",
      attendantId: null
    },
    copyPumps: [],
    copyPumpAttendants: []
  }),
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    ...mapGetters({
      assignedPumps: "pumps/getAlreadyAssignedPumps",
      pumps: "sales/getAllPumps",
      pumpAttendants: "sales/getAllPumpAttendants"
    })
  },
  filters: { timestamp },
  watch: {
    selectedDate(value) {
      if (!value || value !== "") {
        this.attendantToPump.date = value;
      }
    }
  },
  methods: {
    searchRecords() {
      this.menu = false;
      this.$emit("searchRecords", this.attendantToPump.date);
    },
    validateDate(date) {
      return moment(date).isAfter(moment());
    },
    assignAttendantToPumps() {
      const attendantToPump = {
        ...this.attendantToPump,
        attendantId: [this.attendantToPump.attendantId]
      };
      this.$emit("assignAttendantToPumps", attendantToPump);
    },
    close() {
      this.$emit("close", "add");
    }
  }
};
</script>
